import styled from 'styled-components'
import { Link } from "gatsby"

export const Wrapper = styled.div`
  width: 80%;
  max-width: 1408px;
  margin: 0 auto;
`

export const BasePrimary = 'hsla(359, 60%, 50%,';
export const BaseSecondary = 'hsla(0, 0%, 98%,';
export const BaseLight = 'hsla(0, 0%, 100%,';
export const BaseDark = 'hsla(0, 0%, 12%,';

export const Tint100 = '1';
export const Tint80 = '.80';
export const Tint60 = '.60';
export const Tint40 = '.40';
export const Tint20 = '.20';

export const CPrimary = `${BasePrimary + Tint100})`;
export const CSecondary = `${BaseSecondary + Tint100})`;
export const CLight = `${BaseLight + Tint100})`;
export const CDark = `${BaseDark + Tint100})`;

export const TPrimary80 = `${BasePrimary + Tint80})`;
export const TPrimary60 = `${BasePrimary + Tint60})`;
export const TPrimary40 = `${BasePrimary + Tint40})`;
export const TPrimary20 = `${BasePrimary + Tint20})`;
export const TSecondary80 = `${BaseSecondary + Tint80})`;
export const TSecondary60 = `${BaseSecondary + Tint60})`;
export const TSecondary40 = `${BaseSecondary + Tint40})`;
export const TSecondary20 = `${BaseSecondary + Tint20})`;
export const TLight80 = `${BaseLight + Tint80})`;
export const TLight60 = `${BaseLight + Tint60})`;
export const TLight40 = `${BaseLight + Tint40})`;
export const TLight20 = `${BaseLight + Tint20})`;
export const TDark80 = `${BaseDark + Tint80})`;
export const TDark60 = `${BaseDark + Tint60})`;
export const TDark40 = `${BaseDark + Tint40})`;
export const TDark20 = `${BaseDark + Tint20})`;

export const Button = styled(Link)`
  background: ${CPrimary};
  color: ${CLight};
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 16px 20px;
`

export const ButtonA = styled.a`
  background: ${CPrimary};
  color: ${CLight};
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 16px 20px;
`