import React from "react"
import PropTypes from "prop-types"
import { Destyle, GFont } from "./global"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, pageTitle, pageBG, pageText }) => (
  <>
    <Destyle />
    <GFont />
    <Header headerTitle={pageTitle} headerText={pageText} headerBG={pageBG} />
    <main>{children}</main>
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  pageBG: PropTypes.any
}

Layout.defaultProps = {
  pageTitle: ``,
  pageBG: ``
}

export default Layout
