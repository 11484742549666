import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Logo from "../images/logo.png"
import IconMobile from "../images/icon_mobile.svg"
import IconPhone from "../images/icon_phone.svg"
import { Wrapper, TH1, TBody, CPrimary, above, CLight, Button, ButtonA } from "./global"

const HeaderSection = styled.header`
  border-top: 6px solid ${CPrimary};
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 120px;
  padding-top: 50px;
  img {
    mix-blend-mode: lighten;
  }
`

const TopBar = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  ${above.small`
    grid-template-columns: 1fr 3fr;
    nav {
      justify-self: end;
      align-self: center;
      ul {
        li {
          display: inline;
          margin-right: 32px;
          &:last-child {
            margin-right: 0;
          }
          img {
            margin-right: 8px;
          }
        }
      }
    }
  `}
  nav {
    color: ${CLight};
    font-size: 0.875rem;
  }
`

const HeaderWrap = styled.section`
  max-width: 550px;
  margin: 40px auto;
  color: ${CLight};
  text-align: center;
  p {
    padding: 40px 0;
  }
`

const Header = ({ headerTitle, headerText, headerBG }) => (
  <HeaderSection style={{ backgroundImage: `url(${headerBG})` }}>
    <Wrapper>
      <TopBar>
        <Link to="/"><img src={Logo} width="288" height="139" alt="Hoylake Autos" /></Link>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/team">Our Team</Link></li>
            <li><Link to="/testimonials">Testimonials</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><a href="tel:07549820891" title="Call Us"><img src={IconMobile} width="24" height="24" alt="Mobile Phone Icon" />07549 820 891</a></li>
            <li><a href="tel:01516326668" title="Call Us"><img src={IconPhone} width="24" height="24" alt="Phone Icon" />0151 632 6668</a></li>
          </ul>
        </nav>
      </TopBar>

      <HeaderWrap>
        <TH1 color={CLight} align="center">{headerTitle}</TH1>
        <TBody>{headerText}</TBody>
        <ButtonA href="tel:01516326668" title="Call Us">Give Us A Call Today</ButtonA>
      </HeaderWrap>

    </Wrapper>
  </HeaderSection>
)

Header.propTypes = {
  headerTitle: PropTypes.string,
  headerText: PropTypes.string,
  headerBG: PropTypes.any
}

Header.defaultProps = {
  headerTitle: ``,
  headerText: `With 25+ years experience, we offer a full range of services.`,
  headerBG: ``
}

export default Header
