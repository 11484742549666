import { css } from 'styled-components'

export const size = {
  xsmall: 320,
  small: 780,
  med: 1100,
  large: 1200,
}

export const above = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${size[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})