import styled, { createGlobalStyle } from 'styled-components';
import { above } from './queries';

export const GFont = createGlobalStyle`
  body {
    font-family: "Inter", sans-serif;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
  }
`;

export const THero = styled.h1`
  color: ${(props) => props.color};
  font-size: 2.375rem;
  line-height: 2.5rem;
  ${above.xsmall`
    font-size: 2.5rem;
    line-height: 2.5rem;
  `}
  ${above.small`
    font-size: 3rem;
    line-height: 3rem;
  `}
  ${above.med`
    font-size: 3.5rem;
    line-height: 4rem;
  `}
  ${above.large`
    font-size: 4rem;
    line-height: 4.5rem;
  `}
`;

export const TH1 = styled.h1`
  font-size: 2rem;
  line-height: 2rem;
  color: ${(props) => props.color};
  text-align: ${(props) => props.align};
  ${above.xsmall`
    font-size: 2.25rem;
    line-height: 2.5rem;
  `}
  ${above.small`
    font-size: 2.5rem;
    line-height: 2.5rem;
  `}
  ${above.med`
    font-size: 3rem;
    line-height: 3.5rem;
  `}
  ${above.large`
    font-size: 3.5rem;
    line-height: 4rem;
  `}
`;

export const TH2 = styled.h2`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: ${(props) => props.color};
`;

export const TH3 = styled.h3`
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: ${(props) => props.color};
  ${above.xsmall`
    font-size: 1.75rem;
    line-height: 2rem;
  `}
  ${above.small`
    font-size: 2rem;
    line-height: 2.5rem;
  `}
  ${above.med`
    font-size: 2.25rem;
    line-height: 2.5rem;
  `}
  ${above.large`
    font-size: 2.5rem;
    line-height: 3rem;
  `}
`;

export const TH4 = styled.h4`
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  color: ${(props) => props.color};
`;

export const TH5 = styled.h5`
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${(props) => props.color};
  ${above.small`
    font-size: 1.5rem;
    line-height: 2rem;
  `}
  ${above.med`
    font-size: 1.75rem;
    line-height: 2rem;
  `}
  ${above.large`
    font-size: 2rem;
    line-height: 2.5rem;
  `}
`;

export const TH6 = styled.h6`
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: ${(props) => props.color};
  ${above.small`
    font-size: 1.25rem;
    line-height: 1.5rem;
  `}
  ${above.med`
    font-size: 1.5rem;
    line-height: 2rem;
  `}
`;

export const TNav = styled.a`
  font-size: 0.875rem;
  cursor: pointer;
  &.active {
    text-decoration: underline;
  }
`;

export const TBody = styled.p`
  font-weight: 400;
  line-height: 2rem;
  color: ${(props) => props.color};
  &:last-child {
    margin-bottom: 0;
  }
  a {
    text-decoration: underline;
  }
`;

export const TPLarge = styled(TBody)`
  font-size: 1.125rem;
  line-height: 1.5rem;
  ${above.xsmall`
    font-size: 1.125rem;
    line-height: 1.5rem;
  `}
  ${above.small`
    font-size: 1.25rem;
    line-height: 2rem;
  `}
  ${above.med`
    font-size: 1.5rem;
    line-height: 2rem;
  `}
`;

export const TPReg = styled(TBody)`
  font-size: 1rem;
  line-height: 1.5rem;
  ${above.xsmall`
    font-size: 1rem;
    line-height: 1.5rem;
  `}
  ${above.small`
    font-size: 1.125rem;
    line-height: 2rem;
  `}
  ${above.med`
    font-size: 1.125rem;
    line-height: 2rem;
  `}
`;

export const TPSmall = styled(TBody)`
  font-size: 0.8125rem;
  line-height: 1.5rem;
  ${above.xsmall`
    font-size: 0.875rem;
    line-height: 1.5rem;
  `}
  ${above.small`
    font-size: 0.875rem;
    line-height: 1.5rem;
  `}
  ${above.med`
    font-size: 0.875rem;
    line-height: 1.5rem;
  `}
`;