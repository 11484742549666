import React from "react"
import styled from 'styled-components'
import { Wrapper, TPReg, above } from "./global"
import IconEmail from "../images/icon_email.svg"
import IconFacebook from "../images/icon_facebook.svg"
import IconInstagram from "../images/icon_instagram.svg"

const Foot = styled.section`
    text-align: center;
    padding: 40px 0;
    ul {
        li {
            display: inline;
            margin-right: 16px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    ${above.med`
        padding: 60px 0;
        text-align: left;
    `}
`

const FootWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    ${above.med`
        grid-template-columns: 4fr 1fr;
        ul {
            justify-self: end;
            align-self: center;
        }
    `}
`

const Footer = () => (
    <Foot>
        <Wrapper>
            <FootWrap>
                <TPReg>Copyright 2020 &mdash; Hoylake Autos Ltd. All Rights Reserved. 1C Carterton Road, Wirral, CH47 4FD</TPReg>
                <ul>
                    <li><a href="mailto:hoylakeautos@outlook.com" title="Email Us"><img src={IconEmail} width="24" height="24" alt="Email" /></a></li>
                    <li><a href="https://www.facebook.com/Hoylake-Autos-Ltd-107621724042815" title="Follow Us On Facebook"><img src={IconFacebook} width="24" height="24" alt="Facebook" /></a></li>
                    <li><a href="https://www.instagram.com/hoylake_autos/" title="Follow Us On Instagram"><img src={IconInstagram} width="24" height="24" alt="Instagram" /></a></li>
                </ul>
            </FootWrap>
        </Wrapper>
    </Foot>
)
  
export default Footer